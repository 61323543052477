@use "sass:color";
@use "/src/styles/palette";

body {
    --mdc-list-list-item-label-text-weight: 400;
    --mdc-outlined-text-field-label-text-weight: 500;
    --mat-form-field-container-text-weight: 500;

    --mat-sidenav-content-background-color: #{palette.$background};

    .mdc-menu-surface.mat-mdc-select-panel,
    .mdc-menu-surface.mat-mdc-autocomplete-panel {
        padding: 0;
    }
}

.mat-mdc-select {
    // Empty variable will make the font size/weight inherited
    --mat-select-trigger-text-size: ;
    --mat-select-trigger-text-weight: ;
}

.mat-mdc-option.mdc-list-item--disabled.mat-mdc-tooltip-trigger {
    pointer-events: initial;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 40px;
    height: var(--mdc-icon-button-state-layer-size);
    width: var(--mdc-icon-button-state-layer-size);
    line-height: normal;
    padding: 8px;
    vertical-align: middle;

    .mat-mdc-button-touch-target {
        height: 100%;
        width: 100%;
    }
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
    >mat-icon.mat-icon {
        padding: 8px;
    }
}

.mdc-dialog__surface {
    overflow: visible;
}

.mat-mdc-icon-button.mat-unthemed {
    $disabled-color: color.change(palette.$text-light, $alpha: 0.38);
    --mdc-icon-button-icon-color: #{palette.$text-light};
    --mdc-icon-button-disabled-icon-color: #{$disabled-color};

    &:disabled, &[aria-disabled=true] {
        --mdc-icon-button-icon-color: #{$disabled-color};
        --mdc-icon-button-disabled-icon-color: #{$disabled-color};
    }
}

.wf-theme-dark .mat-mdc-icon-button.mat-unthemed {
    $disabled-color: color.change(palette.$border-color, $alpha: 0.38);
    --mdc-icon-button-icon-color: #{palette.$border-color};
    --mdc-icon-button-disabled-icon-color: #{$disabled-color};

    &:disabled, &[aria-disabled=true] {
        --mdc-icon-button-icon-color: #{$disabled-color};
        --mdc-icon-button-disabled-icon-color: #{$disabled-color};
    }
}

.mat-mdc-slide-toggle {
    $dark-color: color.scale(palette.$text-light, $lightness: -50%);
    --mdc-switch-unselected-focus-handle-color: #{$dark-color};
    --mdc-switch-unselected-hover-handle-color: #{$dark-color};
    --mdc-switch-unselected-pressed-handle-color: #{$dark-color};
    --mdc-switch-unselected-handle-color: #{palette.$text-light};
}

.mdc-switch__icon {
    display: none;
}

// For font-awesome icon sets (not floating action button)
.mat-icon.fa,
.mat-icon.fas,
.mat-icon.far,
.mat-icon.fab {
    font-size: 20px;
    line-height: 24px;
    margin-right: unset;
}

.mat-mdc-chip {
    .mdc-evolution-chip__action--presentational {
        cursor: inherit;
    }

    .mat-mdc-chip-action-label mat-icon {
        vertical-align: middle;
    }
}

.wf-theme .mat-mdc-standard-chip {
    .mdc-evolution-chip__cell--primary,
    .mdc-evolution-chip__action--primary,
    .mat-mdc-chip-action-label {
        // For some reason the angular implementation of the chips are set to overflow: visible,
        // overriding the standard material behavior of overflow: hidden.
        // This prevents long chip names from truncating normally, and instead shows the full content overflowing the chip.
        // This fixes it so it instead shows an elipsis.
        overflow: hidden;
    }
}

.mat-mdc-card {
    .mat-mdc-card-avatar.mat-icon {
        font-size: 40px;
        line-height: 40px;
        border-radius: 0;
    }

    .mat-mdc-card-avatar~.mat-mdc-card-header-text:not(:has(.mat-mdc-card-subtitle)) .mat-mdc-card-title {
        line-height: 40px;
    }
}