@use "sass:color";
@use "/src/styles/palette";

$table-padding: 15px;
$parent-div-background-color: color.adjust(whitesmoke, $lightness: -5%, $space: hsl); //Background of the cell in which the table sits
$table-cell-border-bottom-color: $parent-div-background-color; //To blend the border to look transparent
$table-row-left-border-color: palette.$workfacta-blue;
$table-row-border-width: 5px; //Must be same for both left and right borders for the separated line effect
$table-header-background-color: color.adjust(palette.$workfacta-blue, $lightness: 30%, $space: hsl);
$table-header-border-left-color: palette.$secondary-navy;

.child-table-container {
    padding: $table-padding;
    background-color: $parent-div-background-color;

    table.mat-mdc-table {
        border-collapse: collapse;
    }

    &,
    & .mdc-data-table__row:last-child {
        .mat-mdc-cell {
            border-bottom: none;
        }
    }
    
    .mat-mdc-row,
    .mat-mdc-header-row {
        border-left: $table-row-border-width solid $table-row-left-border-color;
        border-bottom: $table-row-border-width solid $table-cell-border-bottom-color;
    }
    
    .mat-mdc-header-row {
        border-left-color: $table-header-border-left-color;
        background-color: $table-header-background-color;
        padding-right: 0;
        height: 48px;
    }
    
    .mat-mdc-row:last-of-type {
        border-bottom: none;
    }
    
    .mat-mdc-cell:last-of-type,
    .mat-mdc-header-cell:last-of-type {
        padding-right: 9px;
    }
    
    .mat-mdc-row {
        padding-right: 0;
        &:not(.wf-row-focused):not(:hover) {
            background-color: white;
        }
    }
    .mat-mdc-header-row {
        position: inherit;
    }
}
